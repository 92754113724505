<template>
  <div ref="chisel" class="chisel-scroll">
    <div class="chisel-container relative">
      <div class="thumbnail-container">
        <div><img @click="changeChiselShow" class="chisel-small" :src="fileBaseUrl + currentProImg.url" /></div>
        <div><img @click="changeSpecShow" class="chisel-spec" :src="specSrc" /></div>
      </div>
      <div class="chisel-content">
        <a-row type="flex" align="middle">
          <a-col :lg="12" :xs="24" class="info-container">
            <div class="info">
              <div class="title">{{ chiseInfo.title }}</div>
              <div class="diameter">{{ chiseInfo.diameter }}</div>
              <div class="tips-container">
                <a-popover placement="rightTop" :get-popup-container="(trigger) => trigger.parentElement" :arrow-point-at-center="true">
                  <template slot="content">
                    {{ chiseInfo.features }}
                  </template>
                  <div class="tips">
                    <img src="@/assets/images/chiselDetail/features.gif" />
                    <span class="tip-title">Features</span>
                  </div>
                </a-popover>
              </div>
              <div class="tips-container">
                <a-popover placement="rightTop" :get-popup-container="(trigger) => trigger.parentElement" :arrow-point-at-center="true">
                  <template slot="content">
                    {{ chiseInfo.service }}
                  </template>
                  <div class="tips">
                    <img src="@/assets/images/chiselDetail/service.gif" />
                    <span class="tip-title">Service</span>
                  </div>
                </a-popover>
              </div>
              <div class="color-container">
                <div v-for="(colorItem,colorIdx) of productImgs" :key="colorIdx" @click="curImgIdx=colorIdx"
                     :style="{ background: `${colorItem.color}` }" class="color-item"></div>
              </div>
              <div class="feature-container">
                <div v-for="(featureItem,featureIdx) of featureList" :key="featureIdx" class="feature-item">{{ featureItem.value }}</div>
              </div>
            </div>
            <div v-if="showChiselPic" class="justChisel"><img :src="fileBaseUrl + currentProImg.url" class="chisel-content-img" /></div>
            <div v-if="!showChiselPic" class="justSpec"><img :src="specSrc" class="chisel-spec-content-img" /></div>
          </a-col>
          <a-col :lg="12" :xs="24" class="cate-container">
            <div class="cate2">
              <div v-for="(cate2Item,cate2Idx) of secondCates" :key="cate2Idx" class="cate-item"
                   :class="curcate2Idx==cate2Idx?'font-black': ''" @click="curcate2Idx=cate2Idx;getProductInfo(cate2Item.categoryId)">
                {{ cate2Item.categoryName }}
              </div>
            </div>
            <!-- <div class="cate3">
              <div v-for="(cate3Item,cate3Idx) of currentThirdCates" :key="cate3Idx" class="cate-item"
                   :class="curcate3Idx==cate3Idx?'font-black': ''" @click="curcate3Idx=cate3Idx;getProductInfo(cate3Item.categoryId)">
                {{ cate3Item.categoryName }}
              </div>
            </div> -->
          </a-col>
        </a-row>
      </div>
      <div class="arrow-container" @click="go2bottom">
        <a-icon type="down" />
      </div>
    </div>
    <div class="chisel-container">
      <div class="chisel-content">
        <a-row type="flex" align="middle">
          <img :src="fileBaseUrl + currentProImg.url" class="chisel-content-img-bottom" />
          <img :src="specSrc" class="chisel-content-img-bottom-phone" />
          <div class="param-container">
            <div class="title">{{ chiseInfo.description }}</div>
            <table class="param-table pcContainer">
              <tr v-for="(pRowItem,pRowIdx) of paramInfosPc" :key="pRowIdx">
                <th>{{ pRowItem[0].name }}</th>
                <td>{{ pRowItem[0].value }}</td>
                <template v-if="pRowItem.length == 2">
                  <th>{{ pRowItem[1].name }}</th>
                  <td>{{ pRowItem[1].value }}</td>
                </template>
              </tr>
            </table>
            <table class="param-table phoneContainer">
              <tr v-for="(pRowItem,pRowIdx) of paramInfosMobile" :key="pRowIdx">
                <th>{{ pRowItem.name }}</th>
                <td>{{ pRowItem.value }}</td>
              </tr>
            </table>
          </div>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import i18nMixin from '@/store/i18n-mixin'
export default {
  name: 'ChiselDetail',
  mixins: [i18nMixin],
  data () {
    return {
      showChiselPic: true,
      fileBaseUrl: this.$baseUrl,
      chiseInfo: {},
      curImgIdx: 0,
      secondCates: [],
      curcate2Idx: '',
      curcate3Idx: ''
    }
  },
  computed: {
    specSrc () {
      const specObj = {
        'ar-SA': require('../../assets/images/chiselDetail/spec-ar.jpg'),
        'en-US': require('../../assets/images/chiselDetail/spec-en.jpg'),
        'es-ES': require('../../assets/images/chiselDetail/spec-es.jpg'),
        'zh-CN': require('../../assets/images/chiselDetail/spec-zh.jpg')
      }
      return specObj[this.currentLang]
    },
    productImgs () {
      return this.chiseInfo?.productImage || []
    },
    currentProImg () {
      return this.productImgs[this.curImgIdx] || {}
    },
    featureList () {
      return this.chiseInfo?.feature || []
    },
    currentThirdCates () {
      const cate3Datas = this.secondCates[this.curcate2Idx]?.children || []
      const curcate3IdTemp = this.curcate3Id || cate3Datas[0]?.categoryId
      if (curcate3IdTemp) {
        this.getProductInfo(curcate3IdTemp)
      }
      return cate3Datas
    },
    paramInfosPc () {
      return this.$listGroup(this.chiseInfo?.parameter, 2)
    },
    paramInfosMobile () {
      return this.chiseInfo?.parameter || []
    }
  },
  created () {
    this.$common.categoryList('0').then(res => {
      this.secondCates = res.data[0]?.children || []
      const queryId = this.$route.query.id
      if (queryId) {
        this.$chisel.productInfoById(queryId).then(res => {
          const dataT = res.data
          if (!dataT) {
            this.$message.warn(this.$t('common.noData'))
            return
          }
          // const { categoryId, parentCategoryIds = '' } = dataT
          const { title, diameter, description, categoryId, parentCategoryIds = '', bizJzhMallExtend } = dataT
          const pCateIds = parentCategoryIds.split(',').filter(Boolean)
          if (pCateIds.length == 2) {
            // 维护在三级下
            this.curcate3Id = categoryId
            this.curcate2Idx = this.secondCates.findIndex(item => item.categoryId == pCateIds[1])
            this.curcate3Idx = this.secondCates[this.curcate2Idx]?.children?.findIndex(item => item.categoryId == categoryId)
            this.chiseInfo = {
              title,
              diameter,
              description,
              categoryId,
              parentCategoryIds,
              ...this.$json2object(bizJzhMallExtend)
            }
          } else if (pCateIds.length === 1) {
            if (pCateIds[0] == '0') {
              // 维护在一级下
              this.curcate2Idx = 0 // 默认选中第一个二级分类
            } else {
              // 维护在二级下
              this.curcate2Idx = this.secondCates.findIndex(item => item.categoryId == categoryId)
            }
            // const { description, categoryId, parentCategoryIds, bizJzhMallExtend } = dataT
            this.chiseInfo = {
              title,
              diameter,
              description,
              categoryId,
              parentCategoryIds,
              ...this.$json2object(bizJzhMallExtend)
            }
          } else {
            // 不可能出现
            this.curcate2Idx = 0 // 默认选中第一个二级分类
          }
        })
      } else {
        this.curcate2Idx = 0 // 默认选中第一个二级分类
        this.getProductInfo(this.secondCates[0].categoryId)
      }
    })
  },
  methods: {
    getProductInfo (cate2Id) {
      this.curImgIdx = 0
      this.$chisel.productInfoByCateId(cate2Id).then(res => {
        const dataT = res.data
        if (!dataT) {
          this.$message.warn(this.$t('common.noData'))
          return
        }
        const { title, diameter, description, categoryId, parentCategoryIds, bizJzhMallExtend } = dataT
        this.chiseInfo = {
          title,
          diameter,
          description,
          categoryId,
          parentCategoryIds,
          ...this.$json2object(bizJzhMallExtend)
        }
      })
    },
    changeChiselShow () {
      this.showChiselPic = true
    },
    changeSpecShow () {
      this.showChiselPic = false
    },
    go2bottom () {
      const scrollElem = this.$refs.chisel
      scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' })
    }
  }
}
</script>
<style lang="less" scoped>
.chisel-scroll {
  height: 100vh;
  overflow-y: scroll;
}

.arrow-container {
  bottom: 60px;
}

.chisel-container {
  height: 100vh;
  background-image:url('~@/assets/images/chiselDetail/01.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 22px;
    font-weight: 900;
    color: #333333;
    margin-bottom: 10px;
  }
  .diameter {
    font-size: 15px;
    font-weight: 900;
    color: #333333;
    margin-bottom: 20px;
  }

  .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      .tips-container {
        width: fit-content;
        margin-bottom: 10px;

        .tips {
          display: flex;
          align-items: center;
          cursor: pointer;

          .tip-title {
            margin-left: 6px;
            font-weight: bold;
            color: #376E25;
          }
        }
      }

      .color-container {
        display: flex;

        .color-item {
          cursor: pointer;
          width: 17px;
          height: 17px;
          margin-right: 10px;
        }
      }

      .feature-container {
        width: 200%;
        margin-top: 10px;

        .feature-item {
          margin-top: 2px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .cate-container {
    display: flex;
    justify-content: flex-end;
    overflow-y: auto;
    max-height: 380px;

    .cate-item {
      cursor: pointer;
      width: 150px;
      padding: 10px 0 4px 0;
      border-bottom: #666666 1px solid;
    }

    .cate3 {
      margin-left: 20px;
    }
  }

  .param-container {
    // margin-left: 20px;
    // width: 80%;
    margin-left: -15%;
    width: 70%;

    .param-table {
      border: #333333 1px solid;

      th {
        width: 200px;
        text-align: center;
      }

      td {
        width: 300px;
      }

      th, td {
        border: #333333 1px solid;
        padding: 4px 10px;
      }
    }
  }
}

@media screen and (min-width:1024px) {
  /* 滚动条整体部分 */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  /*滚动条轨道、滚动条*/
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      border: 5px solid transparent;
  }

  /*滚动条轨道*/
  ::-webkit-scrollbar-track {
      box-shadow: 1px 1px 10px #464646 inset;
  }

  /*滚动条088*/
  ::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      box-shadow: 0 0 0 5px rgb(187, 187, 187) inset;
  }

  /*边角*/
  ::-webkit-scrollbar-corner {
      background: transparent;
  }
  .phoneContainer {
    display: none;
  }
  .thumbnail-container{
    width:68px;
    margin-top: -9%;
    .chisel-small{
      height:40px;
      cursor:pointer;
      height:100px;
      margin-bottom: 6%;
      // margin-left: 40%;
    }
    .chisel-small:hover{
      // opacity: 0.9; //透明度
      // transition: 0.5s; //动画过渡的更加顺滑
      transform: scale(1.1); //放大倍数
    }
    .chisel-spec{
      width:40px;
      height:40px;
      cursor:pointer;
      width:68px;
      height:68px
    }
    .chisel-spec:hover{
      // opacity: 0.9; //透明度
      // transition: 0.5s; //动画过渡的更加顺滑
      transform: scale(1.1); //放大倍数
    }
  }
  .chisel-content {
    width: 65%;
    margin-left: 2%;

    .info {
      width: 40%;
    }
  }

  .chisel-content-img {
    // width: 70px;
  }
  .chisel-content-img:hover{
    transform: scale(1.2);
  }
  .chisel-content-img-bottom{
    width: 500px;
    margin-left: -20%;
  }
  .chisel-content-img-bottom-phone{
    display: none;
  }

  .color-container {
    margin-top: 80px;
  }
  .justSpec{
    width: 600px;
    margin-right: -45%;
    .chisel-spec-content-img {
      width: 500px;
      height: 300px;
      margin-left: 12%;
    }
    .chisel-spec-content-img:hover{
      transform: scale(1.25);
    }
  }
  .justChisel{
    width: 600px;
    margin-right: -50%;
    // .chisel-spec-content-img {
    //   width: 500px;
    //   height: 300px;
    //   margin-left: 12%;
    // }
  }
}

@media screen and (max-width:1000px) {
  .pcContainer {
    display: none;
  }
  .thumbnail-container{
    display: none;
    width:68px;
    margin-top: -9%;
    .chisel-small{
      height:40px;
      cursor:pointer;
      height:100px;
      margin-bottom: 6%;
      // margin-left: 40%;
    }
    .chisel-small:hover{
      // opacity: 0.9; //透明度
      // transition: 0.5s; //动画过渡的更加顺滑
      transform: scale(1.1); //放大倍数
    }
    .chisel-spec{
      width:40px;
      height:40px;
      cursor:pointer;
      width:68px;
      height:68px
    }
    .chisel-spec:hover{
      // opacity: 0.9; //透明度
      // transition: 0.5s; //动画过渡的更加顺滑
      transform: scale(1.1); //放大倍数
    }
  }
  .chisel-content{
    width: 90%;
    .chisel-content-img-bottom{
      width: 500px;
      margin-left: -20%;
      display: none;
     }
    .chisel-content-img-bottom-phone{
      width: 500px;
     }
    .param-container {
      margin-left: 2%;
      width: 100%;
    }

    .info-container {
      display: flex;
      justify-content: unset;
      align-items: center;

      .info {
        width: 70%;
      }
    }

    .cate-container {
      margin-top: 10px;
      max-height: 200px;
      justify-content: unset;
    }
  }

  .chisel-content-img {
    // width: 40px;
    width: 600px;
    // margin-top: 20%;
  }
  .chisel-content-img:hover{
    transform: scale(2);
  }

  .color-container {
    margin-top: 20px;
  }

  .title {
    font-size: 20px;
  }

  .feature-item, .cate-item, th, td {
    font-size: 12px;
  }
}
</style>
